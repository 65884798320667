<template>
  <div>
    <loading
      :active.sync="dados.isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#f68b29"/>

    <div class='botoes'>
      <b-button
        type="button"
        variant="primary"
        size="sm" 
        class="btnProcessar"
        :disabled="dados.disabledCampos"
        @click="dados.filtro_opened = !dados.filtro_opened"><v-icon scale="0.8" name="filter"/> Filtro</b-button>

      <b-button
        type="button"
        variant="success"
        size="sm" 
        class="btnAdicionar"
        @click="abrirJanelaModal()"
        :disabled="dados.disabledCampos"><v-icon scale="0.8" name="plus"/> Adicionar</b-button>
    </div>

    <div v-if="dados.filtro_opened">
      <b-form class="filtro" @submit.prevent="listarDados(1)">
        <InputBasico
          ref="refDeParamensagem"
          label="De"
          name="fieldDe"
          size="sm"
          v-model="dados.de"
          :disabled="dados.disabledCampos"
          type="text"
          class="inputDeParaMensagem"/>
        <InputBasico
          ref="refDeParamensagem"
          label="Para"
          name="fieldPara"
          size="sm"
          v-model="dados.para"
          :disabled="dados.disabledCampos"
          type="text"
          class="inputDeParaMensagem"/>

        <b-button
          type="submit"
          variant="primary"
          size="sm"
          class="btnProcessar"
          :disabled="dados.disabledCampos"><v-icon scale="0.8" name="search"/> Buscar</b-button>
      </b-form>
    </div>

    <label v-if="dados.isConcluido" class="concluido loaderCheck">Concluído com sucesso!</label>

    <div class="loader">
      <Processando v-if="dados.show_loader_erp" mensagem="Carregando dados da Base..."/>
      <Processando v-if="dados.show_processing" mensagem="Processando requisição..."/>
    </div>

    <div v-if="dados.show_loader_erp !== true">
      <h5>Resultado da busca</h5>

      <div class="resultado" v-if="dados.total > 0">
        {{dados.total > 1 ? 'Encontradas':'Encontrada'}} <strong>{{dados.total}}</strong> {{dados.total > 1 ? 'ocorrências':'ocorrência'}}.
      </div>
      <div v-if="dados.lista.length > 0">
        <b-table
          striped
          hover
          bordered
          small
          stacked="md"
          head-variant="dark"
          v-if="dados.show_loader_erp !== true"
          :items="dados.lista"
          :fields="dados.fieldsTable"
          @row-clicked="selecionouLinha">

          <template #head(actions)="row">
            <center>Ações</center>
          </template>

          <template #cell(actions)="row">
            <center>
              <b-button class="btn btn-danger btnAction" size="sm" @click="excluirLinha(`${row.item._id}`)"><v-icon scale="0.8" name="trash"/></b-button>
            </center>
          </template>
        </b-table>

        <div id="footerTable">
          <div>
            <b-form-select v-model="paginacao.limitePorPagina" size="sm" :options="paginacao.limitOptions" @change="listarDados(1)"></b-form-select>
          </div>
          
          <div class="paginacao" v-if="dados.lista.length > 0">
            <b-pagination
              class="paginacao__seletor"
              :total-rows="paginacao.totalDePaginas"
              :per-page="paginacao.limitePorPagina"
              v-model="paginacao.paginaAtual"
              @input="mudarPagina"
              size="sm"/>
          </div>      
        </div>
      </div>
      <div v-else>
        Nenhum registro encontrado.
      </div>
    </div>

    <b-modal
      id="modal-adicionar"
      ref="modal-adicionar"
      :title="dados.itemSel._id !== undefined ? 'Alterar':'Adicionar'"
      @hidden="hiddeModal"
      @show="showModal"
      @ok="handleOk">

      <b-form ref="refDeParamensagem" @submit.stop.prevent="handleSubmit">
        <InputBasico
          ref="refDeParamensagem_c1"
          label="De"
          name="deParaMensagem"
          size="sm"
          v-model="dados.itemSel.de"
          type="text"
          class="inputDeParaMensagem"
          :disabled="dados.disabledCampos"/>
        <InputBasico
          ref="refDeParamensagem_c2"
          label="Para"
          name="deParaMensagem"
          size="sm"
          v-model="dados.itemSel.para"
          type="text"
          class="inputDeParaMensagem"
          :disabled="dados.disabledCampos"/>
      </b-form>

      <Processando v-if="dados.show_processing_modal" mensagem="Processando requisição..."/>

      <b-alert v-model="dados.modalError" variant="danger" dismissible>
        {{dados.erroModal}}
      </b-alert>

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()" :disabled="dados.disabledCampos">Cancelar</b-button>
        <b-button size="sm" variant="success" @click="handleOk" :disabled="dados.disabledCampos">Salvar</b-button>
      </template>
    </b-modal>
    <!-- <ModalAdicionar id="modal-adicionar" :selecionado="dados.selecionado" :resetFn="adicionar"/> -->
  </div>
</template>

<script>
  import {
      getListaDeParaMensagem,
      addDeParaMensagem,
      delDeParaMensagem,
      updDeParaMensagem
  } from '../../../common/services/deparaService';
  import InputBasico from '../../../components/inputs/InputBasico';
  import Processando from '@/components/comuns/Processando';
  import { getUserObj } from "@/common/localStorage/localStorage";

  export default {
    name:'',
    data() {
      return {
        dados: {
          de: '',
          para: '',
          itemSel: {},
          checked: false,
          show_loader_erp: false,
          show_processing: false,
          filtro_opened: true,
          show_processing_modal: false,
          erroModal: '',
          modalError: false,
          disabledCampos: false,
          lista: [],
          total: 0,
          key: false,
          isLoading: true,
          fieldsTable: [
            // { key: "_id", label: "ID", sortable: true },
            { key: "codERP", label: "De", sortable: true },
            { key: "codSenior", label: "Para", sortable: true },
            { key: "actions", label: "Ações", sortable: false }
          ],
        },
        paginacao: {
          paginaAtual: 1,
          limitePorPagina: 10,
          totalDePaginas: 0,
          limitOptions: [
            { value: 10, text: '10 por página' },
            { value: 25, text: '25 por página' },
            { value: 50, text: '50 por página' },
            { value: 75, text: '75 por página' },
            { value: 100, text: '100 por página' }
          ]
        },
      }
    },
    components:{
      InputBasico,
      Processando
    },
    async created() {
      await this.listarDados(1);
    },
    methods: {
      async listarDados(pagina) {
        this.dados.lista = [];
        this.dados.show_loader_erp = true;
        this.dados.disabledCampos = true;

        const resultado = (await getListaDeParaMensagem((getUserObj()).empresa.id, this.dados.de, this.dados.para, this.paginacao.limitePorPagina, pagina)).data;
        this.dados.lista = resultado.list;
        this.dados.total = resultado.total;
        this.paginacao.totalDePaginas = parseInt(this.paginacao.limitePorPagina * resultado.pages);

        this.dados.show_loader_erp = false;
        this.dados.disabledCampos = false;
        this.dados.isLoading = false;
      },
      async salvar() {
        let body = {};
        let resultado = {};
        this.dados.show_processing_modal = true;
        this.dados.disabledCampos = true;

        if (this.dados.itemSel._id !== undefined) {
          body = {
            _id: this.dados.itemSel._id,
            idCliente: (getUserObj()).empresa.id,
            codSenior: this.dados.itemSel.para,
            codERP: this.dados.itemSel.de
          };

          resultado = await updDeParaMensagem(body);
        } else {
          body = {
            idCliente: (getUserObj()).empresa.id,
            codSenior: this.dados.itemSel.para,
            codERP: this.dados.itemSel.de
          };

          resultado = await addDeParaMensagem(body);
        }
              
        this.dados.show_processing_modal = false;
        this.dados.disabledCampos = false;
        return resultado;
      },
      confirmaExclusao() {
        let retorno = this.$bvModal.msgBoxConfirm('Deseja realmente excluir este registro?', {
          title: 'Confirmação',
          size: 'lm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        });
        return retorno;
      },
      async excluirLinha(id) {
        if (await this.confirmaExclusao()) {
          this.dados.disabledCampos = true;
          this.dados.show_processing = true;
          let result = await delDeParaMensagem(id);
          this.dados.disabledCampos = false;
          this.dados.show_processing = false;

          await this.listarDados(this.paginacao.paginaAtual);
        }
      },
      abrirJanelaModal(value) {
        if (!value) {
          this.dados.itemSel = {};
        }
        this.$bvModal.show('modal-adicionar');
      },
      selecionouLinha(item,index,event) {
        this.dados.itemSel = {
          _id: item._id,
          de: item.codERP,
          para: item.codSenior
        };

        this.abrirJanelaModal(this.dados.itemSel);
      },
      checkFormValidity() {
        let valid = true;

        if (this.dados.itemSel.de === '' || this.dados.itemSel.de === undefined) {
            this.dados.erroModal = 'É obrigatório preencher o campo "De"';
            this.dados.modalError = true;
            return false;
        }
        
        if (this.dados.itemSel.para === '' || this.dados.itemSel.para === undefined) {
            this.dados.erroModal = 'É obrigatório preencher o campo "Para"';
            this.dados.modalError = true;
            return false;
        }
        return valid
      },
      hiddeModal() {
        this.dados.modalError = false;
      },
      showModal() {
        // this.$nextTick(function () {
        //     this.$refs.refDeParamensagem_c1.focus()
        // })
      },
      handleOk(bvModalEvt) {
        // Parando processamento
        bvModalEvt.preventDefault()
        // executando o submit
        this.handleSubmit()
      },
      async handleSubmit() {
        this.dados.erroModal = '';
        this.dados.modalError = false;

        if (!this.checkFormValidity()) {
          return
        }

        //Envia dados ao servidor  
        const resp = await this.salvar();

        if (resp.data.erro) {
          this.dados.erroModal = resp.data.erro;
          this.dados.modalError = true;
        } else {
          this.listarDados(this.paginacao.paginaAtual);

          //Fecha a janela
          this.$nextTick(() => {
            this.$bvModal.hide('modal-adicionar');
          })
        }
      },
      async mudarPagina(p) {
        const {paginaAtual} = this.paginacao;
        this.listarDados(paginaAtual);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/common/scss/breakpoints.scss";

  .filtro {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: $medium-size) {
      width: 100%;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 0.8em;
    }
  }
</style>